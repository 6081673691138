<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="onAddNewCat" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(name)="data">
                    <div
                        class="column-width-100 bp-category-name">
                        <div>{{ data.item['name_' + lng] }} </div>
                        <i 
                            v-if="variantAttribute"
                            class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                            @click="onEditCatName(data.item)"
                        ></i>
                    </div>
                </template>
                <template #cell(area)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.area"
                                  @change="v => data.item.area = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'area', data.item.area, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.area === null || data.item.area === '' || data.item.area <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(rent)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.rent"
                                  @change="v => data.item.rent = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'rent', data.item.rent, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.rent === null || data.item.rent === '' || data.item.rent <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.months"
                                  @change="v => data.item.months = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'months', data.item.months, 1, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.months === null || data.item.months === '' || data.item.months <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="6">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="budgetForm"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addItem"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form03_159',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        CSetBFCategoryName,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '03-159',
                name_kk: 'Үй-жайды жалдауға төлеуге арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов на оплату аренды за помещение'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            categoryFieldNames: {ru: "name_ru", kk: "name_kk"}
        };
    },
    methods: {
        addItem(nameRu, nameKk) {
            const item = { 
                id: this.newRowStartId,
                name_ru: nameRu,
                name_kk: nameKk
            };
            this.newRowStartId--;
            this.itemUpdate(item);

            Object.defineProperty(item, 'rent_month', {
                get: function () {
                    const rentMonth = item.area * item.rent;
                    return parseFloat((Math.round(rentMonth * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = item.rent_month * item.months / 1000;
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            this.budgetForm.push(item);
        },

        input12(item, field, value) {
            // nextTick добавлен тк не без него не работает реактивность при событии keyPress.enter если предыдущее значение = 0 
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? parseFloat(parseFloat(value).toFixed(0)) : 0
                if (parseInt(newVal) > 12) {
                    this.$set(item, field, 0);
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('not_more_12'));
                } else {
                    this.$set(item, field, newVal);
                }
            })
        }, // проверка value>12

        async itemUpdate(item) {
            this.$set(item, 'area', 0);
            this.$set(item, 'rent', 0);
            this.$set(item, 'months', 12);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const values = await response.json();
                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        name_ru: val.name_ru,
                        name_kk: val.name_kk,
                        area: val.area,
                        rent: val.rent,
                        months: val.months,
                        files: val.files,
                        row_files: val.row_files,
                        itemToDelete: false,
                    };
                    Object.defineProperty(item, 'rent_month', {
                        get: function () {
                            const rentMonth = item.area * item.rent;
                            return parseFloat((Math.round(rentMonth * 100) / 100).toFixed(2));
                        }
                    });
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = item.rent_month * item.months / 1000;
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        noAbc: function (event) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                if (this.isSavingForbidden(row, this.tableFields, ['area', 'rent', 'months'], [])) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'name_kk', row.name_kk);
                this.$set(item, 'area', parseFloat(row.area));
                this.$set(item, 'rent', parseFloat(row.rent));
                this.$set(item, 'months', parseInt(row.months));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (values.length && this.variantAttribute) {
                this.save(values);
            }
            else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_03_159.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name',
                        label: this.getFormText('space_name')
                    },
                    {
                        key: 'area',
                        label: this.getFormText('rent_area')
                    },
                    {
                        key: 'rent',
                        label: this.getFormText('quad_rent')
                    },
                    {
                        key: 'rent_month',
                        label: this.getFormText('monthly_rent')
                    },
                    {
                        key: 'months',
                        label: this.getFormText('month_count')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>    
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .filter-actions-flex {
        display: flex;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
</style>